  .callTypeahead .ant-select-selector {
      background: white;
  }

  .callTypeahead .ant-input {
      height: 52px;
    }
  
    .callTypeahead .ant-input[type="text"] {
      font-size: 80px;
      font-weight: 100;
    }
  